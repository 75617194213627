<template>
  <div class="elv-journals-table-cell">
    <div class="elv-journals-table-cell_journalType">
      <div class="elv-journals-table-cell_journal-entry">{{ props.params?.data?.journal?.journalType?.name }}</div>
      <el-tooltip
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
      >
        <div>
          <p>
            {{ props.params?.data?.additionalItem }}
          </p>
        </div>

        <template #content>
          <div class="elv-table-tips-content elv-table-tips_journal-entry">
            {{ props.params?.data?.additionalItem }}
          </div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell_journalType {
    .elv-journals-table-cell_journal-entry {
      margin-bottom: 6px;
      overflow: hidden;
      color: #0e0f11;
      text-overflow: ellipsis;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }

    p {
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
<style lang="scss">
.elv-table-tips_journal-entry {
  width: 240px;
}
</style>
