<template>
  <div class="elv-journals-table-cell">
    <div class="elv-journals-table-cell__account">
      <el-tooltip
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
      >
        <div>
          <p>{{ formatLanguageContent(props.params.data?.chartOfAccount?.name) }}</p>
        </div>

        <template #content>
          <div class="elv-table-tips-content">
            {{ formatLanguageContent(props.params.data?.chartOfAccount?.name) }}
          </div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatLanguageContent } from '@/lib/utils'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell__account {
    p {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
