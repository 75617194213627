<template>
  <div class="elv-reconciliation-unmatched-detail-page">
    <Header />
    <HeaderTabs />
    <section v-loading="loading" class="elv-reconciliation-unmatched-container">
      <div class="elv-reconciliation-unmatched-header">
        <div class="elv-reconciliation-unmatched-header-column">
          <h3>{{ t('report.Transactions') }}</h3>
        </div>
        <div class="elv-reconciliation-unmatched-header-column operating">
          <h3>{{ t('common.ledger') }}</h3>
          <div>
            <p>
              {{ t('common.difference') }}:
              <span :class="{ 'is-error': differenceAmount !== 0 }">{{ differenceAmount }}</span>
            </p>
            <elv-button
              type="primary"
              round
              width="88"
              height="30"
              :loading="matchLoading"
              @click="onMatchReconciliation"
              >{{ t('report.Match') }}</elv-button
            >
          </div>
        </div>
      </div>
      <div class="elv-reconciliation-unmatched-content">
        <div class="elv-reconciliation-unmatched-left">
          <TransactionTable
            ref="transactionTableRef"
            :transactions-params="transactionsParams"
            :table-height="tableHeight"
            :disabled-selected="disabledSelected"
            :table-data-selected="transactionTableDataSelected"
            :table-data="reconciliationStore.unmatchedTransactions"
            :table-loading="transactionsLoading"
            :selected-total-amount="selectedTrxTotalAmount"
            @on-change-page="onChangeTransactionsPage"
            @onSelectionChange="onSelectionTransactionChange"
            @on-change-page-size="onChangeTransactionsPageSize"
          />
          <div class="elv-reconciliation-unmatched-left__line" />
        </div>

        <GeneralLedgerTable
          ref="generalLedgerTableRef"
          class="elv-reconciliation-unmatched-right"
          :ledger-params="ledgerParams"
          :table-height="tableHeight"
          :disabled-selected="disabledSelected"
          :table-data-selected="ledgerTableDataSelected"
          :table-data="reconciliationStore.unmatchedLedgerData"
          :table-loading="ledgerLoading"
          :selected-total-amount="selectedLedgerTotalAmount"
          @on-change-page="onChangeLedgerPage"
          @onSelectionChange="onSelectionLedgerChange"
          @on-change-page-size="onChangeLedgerPageSize"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { ElMessage } from 'element-plus'
import Header from '../components/Header.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import TransactionTable from './components/TransactionTable.vue'
import GeneralLedgerTable from './components/GeneralLedgerTable.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const { t } = useI18n()
const route = useRoute()
const reconciliationStore = useReconciliationStore()

const transactionsParams = ref({
  page: 1,
  limit: 20
})
const loading = ref(false)
const matchLoading = ref(false)
const transactionsLoading = ref(false)
const transactionTableDataSelected: any = ref([])
const ledgerTableDataSelected: any = ref([])
const ledgerLoading = ref(false)
const transactionTableRef = ref()
const generalLedgerTableRef = ref()
const ledgerParams = ref({
  page: 1,
  limit: 20
})

const tableHeight = computed(() => {
  return `calc(100vh - 311px)`
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const disabledSelected = computed(() => {
  if (transactionTableDataSelected.value.length || ledgerTableDataSelected.value.length) {
    return transactionTableDataSelected.value?.[0]?.currency ?? ledgerTableDataSelected.value?.[0]?.currency?.symbol
  }
  return ''
})

const selectedTrxTotalAmount = computed(() => {
  const selectedValue = transactionTableDataSelected.value.reduce((acc: Big, cur: any) => {
    return acc.plus(cur.amount !== null && cur.amount !== '0' ? cur.amount : 0)
  }, Big(0))
  return selectedValue.toNumber()
})

const selectedLedgerTotalAmount = computed(() => {
  const selectedValue = ledgerTableDataSelected.value.reduce((acc: Big, cur: any) => {
    const amount = cur.credit !== null && cur.credit !== '0' && cur.credit !== 0 ? cur.credit : cur.debit
    return acc.plus(amount)
  }, Big(0))
  return selectedValue.toNumber()
})

const differenceAmount = computed(() => {
  const differenceValue = Big(selectedLedgerTotalAmount.value).minus(selectedTrxTotalAmount.value)
  return differenceValue.toNumber()
})

const getTransactionsTableData = async () => {
  try {
    transactionsLoading.value = true
    await reconciliationStore.fetchReconciliationUnmatchedTrxList(
      entityId.value,
      reconciliationSetId.value,
      transactionsParams.value
    )
    transactionTableRef.value?.tableListRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    transactionsLoading.value = false
  }
}

const getLedgerTableData = async () => {
  try {
    ledgerLoading.value = true
    await reconciliationStore.fetchReconciliationUnmatchedLedgerList(
      entityId.value,
      reconciliationSetId.value,
      ledgerParams.value
    )
    generalLedgerTableRef.value?.ledgerTableRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    ledgerLoading.value = false
  }
}

const onSelectionTransactionChange = (val: any) => {
  transactionTableDataSelected.value = val
}

const onSelectionLedgerChange = (val: any) => {
  ledgerTableDataSelected.value = val
}

const onChangeLedgerPageSize = (limit: number) => {
  ledgerParams.value.limit = limit
  ledgerParams.value.page = 1
  getLedgerTableData()
}

const onChangeLedgerPage = (page: number) => {
  ledgerParams.value.page = page
  getLedgerTableData()
}

const onChangeTransactionsPageSize = (limit: number) => {
  transactionsParams.value.limit = limit
  transactionsParams.value.page = 1
  getTransactionsTableData()
}

const onChangeTransactionsPage = (page: number) => {
  transactionsParams.value.page = page
  getTransactionsTableData()
}

const onMatchReconciliation = async () => {
  if (!transactionTableDataSelected.value.length || !ledgerTableDataSelected.value.length) return
  try {
    matchLoading.value = true
    await ReconciliationApi.manualReconciliation(entityId.value, reconciliationSetId.value, {
      transactionIds: transactionTableDataSelected.value.map((item: any) => item.transactionId),
      generalLedgerIds: ledgerTableDataSelected.value.map((item: any) => item.generalLedgerId)
    })
    transactionTableDataSelected.value = []
    ledgerTableDataSelected.value = []
    transactionTableRef.value?.tableListRef?.clearSelection()
    generalLedgerTableRef.value?.ledgerTableRef?.clearSelection()
    matchLoading.value = false
    ElMessage.success(t('message.success'))
    loading.value = true
    await reconciliationStore.unmatchedListDataInit(
      entityId.value,
      reconciliationSetId.value,
      transactionsParams.value,
      ledgerParams.value
    )
    loading.value = false
  } catch (error: any) {
    matchLoading.value = false
    loading.value = false
    console.log(error)
    ElMessage.error(error.message)
  }
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-reconciliation-unmatched') {
        loading.value = true
        await reconciliationStore.unmatchedListDataInit(
          entityId.value,
          reconciliationSetId.value,
          transactionsParams.value,
          ledgerParams.value
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  reconciliationStore.unmatchedTransactions = { totalCount: 0, list: [] }
  reconciliationStore.unmatchedLedgerData = { totalCount: 0, list: [] }
  ledgerTableDataSelected.value = []
  transactionTableDataSelected.value = []
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-unmatched-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-reconciliation-unmatched-container {
    flex: 1;
    min-height: 0;
    position: relative;
  }
}

.elv-reconciliation-unmatched-header {
  display: flex;
  height: 30px;
  padding-top: 9px;
  padding-bottom: 8px;
  box-sizing: content-box;

  .elv-reconciliation-unmatched-header-column {
    // flex: 1;
    // flex-grow: 1;
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;

    &:first-of-type {
      padding-left: 20px;
    }

    h3 {
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      color: #1e2024;
      font-weight: 600;
    }

    &.operating {
      justify-content: space-between;
      padding-right: 24px;

      > div {
        display: flex;
        align-items: center;

        p {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          font-weight: 400;
          margin-right: 8px;

          span {
            color: #749a01;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 8px;

            &.is-error {
              color: #eb5a52;
            }
          }
        }
      }
    }
  }
  // > div {
  //   padding-left: 20px;
  // }
}

.elv-reconciliation-unmatched-content {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  align-items: center;
  position: relative;

  .elv-reconciliation-unmatched-left,
  .elv-reconciliation-unmatched-right {
    height: 100%;
    width: 50% !important;
    position: relative;
  }

  .elv-reconciliation-unmatched-left {
    display: flex;

    .elv-reconciliation-unmatched-left__line {
      width: 6px;
      box-sizing: border-box;
      height: calc(100% - 54px);
      border: 1px solid #ced7e0;
      background: #eef4fb;
    }
  }
}
</style>
<style lang="scss">
.elv-reconciliation-unmatched-content {
  .el-table {
    // 宽度为外层容器的宽度
    width: 100%;

    .el-table__empty-block {
      width: 100% !important;
    }
  }
}
</style>
