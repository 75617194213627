<template>
  <div>
    <div class="elv-reconciliation-unmatched-general-ledger-table-wrapper">
      <el-table
        ref="ledgerTableRef"
        v-loading="props.tableLoading"
        :data="props.tableData?.list"
        border
        stripe
        width="100%"
        height="100%"
        show-summary
        row-key="generalLedgerId"
        :summary-method="setSummaries"
        class="elv-reconciliation-unmatched-table"
        header-cell-class-name="elv-reconciliation-unmatched-table-header__cell"
        header-row-class-name="elv-reconciliation-unmatched-table-header"
        row-class-name="elv-reconciliation-unmatched-table-row"
        cell-class-name="elv-reconciliation-unmatched-table-row__cell"
        @row-click="onCellClicked"
        @selection-change="onSelectionChange"
      >
        <el-table-column
          type="selection"
          width="40"
          :selectable="selectable"
          fixed="left"
          label-class-name="elv-reconciliation-unmatched-table-row__index is-last-column"
          reserve-selection
        />
        <el-table-column width="100" :label="t('common.date')">
          <template #default="{ row }">
            <DateJournalNo :params="{ data: row }" />
          </template>
        </el-table-column>

        <el-table-column :label="t('report.journalType')" width="140" show-overflow-tooltip>
          <template #default="{ row }">
            <journalTypeCell :params="{ data: row }" />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Dr/Cr')" width="55">
          <template #default="{ row }">
            <BalanceCell :params="{ data: row }" />
          </template>
        </el-table-column>
        <el-table-column :label="t('common.account')" width="150">
          <template #default="{ row }">
            <AccountCell :params="{ data: row }" />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.auxiliaryCode')" width="150">
          <template #default="{ row }">
            <AuxiliaryCode :params="{ data: row }" />
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Currency')"
          min-width="80"
          class-name="elv-reconciliation-unmatched-table-row__currency"
          label-class-name="elv-reconciliation-unmatched-table-row__currency"
        >
          <template #default="{ row }">
            <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" />
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Amount')"
          width="100"
          align="right"
          header-align="right"
          fixed="right"
          class-name="elv-reconciliation-unmatched-table-row__amount"
          label-class-name="elv-reconciliation-unmatched-table-row__amount el-table-fixed-column--right is-first-column"
        >
          <template #default="{ row }">
            <TextCell
              :text="
                formatNumber(
                  row.credit !== null && row.credit !== '0' && Number(row.credit) !== 0 ? row.credit : row.debit,
                  20
                )
              "
              :title-desc="
                formatNumber(
                  row.credit !== null && row.credit !== '0' && Number(row.credit) !== 0 ? row.credit : row.debit,
                  20
                )
              "
              align="right"
              :weight="500"
            />
          </template>
        </el-table-column>
      </el-table>
      <elv-pagination
        class="elv-journals-list-pagination"
        size-popper-class="elv-journals-pagination__sizes-popper"
        :limit="props.ledgerParams.limit"
        :current-page="props.ledgerParams.page"
        :total="props.tableData.totalCount"
        @current-change="onChangePage"
        @size-change="onChangePageSize"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/lib/utils'
import AccountCell from './Cell/AccountCell.vue'
import BalanceCell from './Cell/BalanceCell.vue'
import type { TableColumnCtx } from 'element-plus'
import DateJournalNo from './Cell/DateJournalNo.vue'
import journalTypeCell from './Cell/journalTypeCell.vue'
import TextCell from '@/components/Base/Table/TextCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import AuxiliaryCode from '../../components/Cell/AuxiliaryCodeCell.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  ledgerParams: {
    type: Object,
    required: true
  },
  tableDataSelected: {
    type: Array,
    required: true
  },
  selectedTotalAmount: {
    type: Number,
    required: true
  },
  disabledSelected: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const ledgerTableRef = ref()
// eslint-disable-next-line no-unused-vars
const reconciliationStore = useReconciliationStore()
const emit = defineEmits(['onChangePage', 'onChangePageSize', 'onSelectionChange'])

interface SummaryMethodProps<T = any> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onSelectionChange = (val: any) => {
  emit('onSelectionChange', val)
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any) => {
  console.log(row)
}

const selectable = (row: any) => {
  if (props.disabledSelected === '') return true
  return row.currency.symbol === props.disabledSelected
}

const setSummaries = (param: SummaryMethodProps) => {
  const { columns } = param
  const sums: string[] = []
  columns.forEach((column, index) => {
    if (index === 1) {
      sums[index] = `${t('common.selected')}: ${formatNumber(props.tableDataSelected.length)}`
      return
    }
    if (index === 7) {
      sums[index] = `${props.selectedTotalAmount}`
      return
    }
    // eslint-disable-next-line no-param-reassign
    column.rowSpan = 0
    // eslint-disable-next-line no-param-reassign
    column.colSpan = 0
  })
  nextTick(() => {
    setTimeout(() => {
      const current = ledgerTableRef.value.$el
        .querySelector('.el-table__footer-wrapper')
        .querySelector('.el-table__footer')
      const cell = current.rows[0].querySelectorAll('.el-table__cell')
      cell?.forEach((element: any, index: number) => {
        if (index > 1 && index < 7) {
          // eslint-disable-next-line no-param-reassign
          element.style.display = 'none'
        }
      })
      cell[2].style.display = 'none'
      cell[1].colSpan = '6'
      cell[1].firstChild.className = 'cell elv-reconciliation-unmatched-table-row__total'
      cell[7].firstChild.className = 'cell elv-reconciliation-unmatched-table-row__total-amount'
    }, 10)
  })
  return sums
}

defineExpose({ ledgerTableRef })
</script>

<style lang="scss">
.elv-reconciliation-unmatched-general-ledger-table-wrapper {
  width: 100%;
  height: calc(100% - 54px);
  position: relative;
}

.elv-reconciliation-unmatched-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-table-header-border: 1px solid #e4e7eb;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  &.is-scrolling-middle,
  &.is-scrolling-right {
    .elv-reconciliation-unmatched-table-header
      .el-table-fixed-column--left.elv-reconciliation-unmatched-table-row__index.is-last-column.el-table__cell {
      border-right: none !important;
    }
  }

  &.is-scrolling-none {
    .el-table__body-wrapper {
      .elv-reconciliation-unmatched-table-row .elv-reconciliation-unmatched-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #edf0f3 !important;
      }
    }

    .el-table__header-wrapper {
      .elv-reconciliation-unmatched-table-header .elv-reconciliation-unmatched-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #e4e7eb !important;
      }
    }
  }

  .elv-reconciliation-unmatched-table-header {
    background: #eef4fb;

    .elv-reconciliation-unmatched-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.el-table-fixed-column--left {
        background: #eef4fb !important;
        position: relative !important;
        border-right: var(--el-table-header-border) !important;
        left: 0px !important;

        &.elv-reconciliation-unmatched-table-row__index {
          position: sticky !important;
          z-index: 10;

          .cell {
            display: none;
          }
        }
      }

      &.elv-reconciliation-unmatched-table-row__amount.is-right {
        position: sticky !important;
        z-index: 10;
        right: 0;
      }

      &.elv-reconciliation-unmatched-table-row__currency.el-table-fixed-column--left.is-last-column::before {
        box-shadow: none !important;
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-unmatched-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.elv-reconciliation-unmatched-table-row__amount .elv-base-cell-text {
      white-space: nowrap;
      overflow: hidden;
    }

    .elv-reconciliation-unmatched-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    height: 42px;

    .el-table__footer {
      height: 100%;

      .cell.elv-reconciliation-unmatched-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-unmatched-table-row__total-amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.elv-journals-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
